.wpo-section-title {
	margin-bottom: 60px;
	text-align: center;

	.section-titl-shape-1 {
		position: absolute;
		top: 0;
		left: 44%;
		transform: translateX(-50%);
		z-index: -1;
		svg {
			g {
				fill: #274DCF;
			}
		}
	}

	.section-titl-shape-2 {
		position: absolute;
		top: 0;
		left: 55%;
		transform: translateX(-50%);
		z-index: -1;
		svg {
			g {
				fill: #DE2045;
			}
		}
	}

	@include media-query(767px) {
		margin-bottom: 40px;
	}

	span {
		text-transform: capitalize;
		font-size: 15px;
		color: $theme-primary-color;
		font-family: $heading-font;
		position: relative;
		font-weight: 600;
		padding: 0 20px;

		&::before {
			position: absolute;
			left: 0;
			top: 50%;
			content: '';
			width: 8px;
			height: 2px;
			background: $theme-primary-color;
			transform: translateY(-50%);
		}

		&::after {
			position: absolute;
			right: 0;
			top: 50%;
			content: '';
			width: 8px;
			height: 2px;
			background: $theme-primary-color;
			transform: translateY(-50%);
		}
	}

	h2 {
		font-size: 42px;
		margin: 0;
		text-transform: capitalize;
		font-family: $heading-font;
		font-weight: 600;
		line-height: 56px;
		margin-top: 15px;

		@include media-query(767px) {
			font-size: 32px;
			line-height: 40px;
			margin-top: 10px;
		}

		@include media-query(330px) {
			font-size: 30px;
		}

	}

	p {
		font-size: 18px;
	}

}