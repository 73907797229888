/*======================================
6. Home-style-3
 =======================================*/

/* 6.1 wpo-about-section-s2 */
.wpo-about-section-s2 {
    padding-bottom: 120px;

    @media (max-width:991px) {
        padding-bottom: 90px;
    }

    @media (max-width:767px) {
        padding-bottom: 80px;
    }

    .wpo-about-wrap {}

    .wpo-about-text {
        .wpo-section-title {
            text-align: left;
            margin-bottom: 30px;

            span {
                font-family: $heading-font;
                text-transform: capitalize;
                font-weight: 600;
            }

            h2 {
                text-transform: capitalize;
                font-weight: 500;
            }
        }

        .sub-text {
            margin-bottom: 0;
            font-family: $base-font;
            font-size: 18px;
            font-weight: 400;
            color: #777777;
        }
    }

    .about-content {
        margin-top: 30px;

        .images {
            border: 1px solid #9A9A9A;
            border-radius: 100px;
            border-style: dashed;
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {}
        }

        .text {
            margin-top: 25px;

            h3 {
                font-size: 24px;
                font-weight: 500;
                color: #646464;

                @media (max-width:1199px) {
                    font-size: 19px;
                }
            }

            p {
                font-family: $heading-font;
                font-size: 16px;
                font-weight: 400;
                color: #777777;
                margin-bottom: 0;
            }
        }
    }
}

/* 6.2 wpo-portfolio-section */
.wpo-portfolio-section {
    position: relative;

    .portfolio-grids {

        .grid {
            float: left;

            img {
                width: 100%;
            }
        }
    }

    .grid {
        .img-holder {
            position: relative;
            cursor: pointer;

            .hover-content {
                transition: all .3s;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                opacity: 0;
                text-align: center;
                width: 260px;

                span {
                    color: #6788a7;
                }

                h4 {
                    font-size: 30px;
                    font-weight: 600;

                    a {
                        color: $dark-gray;

                        &:hover {
                            color: $theme-primary-color;
                        }
                    }
                }

                i {
                    font-size: 30px;
                    color: $dark-gray;
                }
            }

            &:before {
                position: absolute;
                left: 2%;
                top: 2%;
                width: 96%;
                height: 96%;
                content: "";
                background: rgba(0, 201, 157, 0.792);
                opacity: 0;
                transition: all .3s;
                transform: scale(0);
            }
        }
    }

    .img-holder:hover .hover-content {
        opacity: 1;
    }

    .img-holder:hover {
        &:before {
            opacity: 1;
            transform: scale(1);
        }

    }

}

/* 6.3 wpo-team-section */
.wpo-team-section {
    position: relative;
    padding-bottom: 90px;

    @media (max-width:991px) {
        padding-bottom: 60px;
    }

    @media (max-width:767px) {
        padding-bottom: 50px;
    }

    .wpo-team-wrap {
        .wpo-team-item {
            text-align: center;
            padding: 30px;
            // background: $section-bg-color;
            // border-radius: 50%;
            position: relative;
            // transition: all .3s;
            // max-width: 350px;
            margin: 0 auto;
            margin-bottom: 30px;

            .wpo-team-img {
                overflow: hidden;
                border-radius: 50%;
                border: 5px solid $white;
                position: relative;

                img {
                    width: 100%;
                    border-radius: 50%;
                    transform: scale(1);
                    transition: all .3s;
                }
            }

            .wpo-team-text {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                flex-direction: column;
                background: transparentize($theme-primary-color, .2);
                border-radius: 50%;
                transition: all .3s;
                transform: translateY(100%);
                padding-top: 15px;

                span {
                    text-transform: capitalize;
                    font-size: 15px;
                    font-weight: 600;
                    color: $section-bg-color-s2;
                }

                h2 {
                    font-size: 32px;
                    color: $white;
                    margin-top: 10px;
                    text-transform: capitalize;

                    @media(max-width:1200px) {
                        font-size: 25px;
                    }
                }

                .button {
                    a {
                        display: inline-block;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        border: 1px solid $white;
                        color: $white;
                        margin-top: 20px;
                        border-radius: 50%;
                        position: relative;
                        overflow: hidden;

                        &::after {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background: transparentize($white, .5);
                            transform: translateX(-100%);
                            content: "";
                            transition: all .5s;
                        }

                        &:hover {
                            &::after {
                                transform: translateX(100%);
                            }
                        }
                    }
                }
            }

            // &:hover,
            // &.active {
            //     .wpo-team-img {
            //         img {
            //             transform: scale(1.2);
            //         }
            //     }

            //     .wpo-team-text {
            //         transform: translateY(0);
            //     }

            //     background: transparentize($theme-primary-color, .8);
            // }
        }
    }
}

/* 6.4 wpo-cta-section */
.wpo-cta-section {
    padding-top: 0;


    .wpo-cta-wrap {
        padding: 80px 85px;
        background: url(../../images/cta-bg.jpg) no-repeat center center;
        background-size: cover;
        border-radius: 6px;

        @media(max-width:991px) {
            padding: 40px 35px;
        }

        @media(max-width:450px) {
            padding: 10px;
        }

        .wpo-cta-item {
            padding: 100px 80px;
            background: transparentize($dark-gray, .1);
            display: flex;
            align-items: center;
            position: relative;
            z-index: 1;
            border-radius: 6px;
            overflow: hidden;

            @media(max-width:1200px) {
                flex-wrap: wrap;
                justify-content: center;
                text-align: center;
            }

            @media(max-width:991px) {
                padding: 50px 40px;
            }

            @media(max-width:450px) {
                padding: 20px 15px;
            }


            .wpo-cta-text {
                margin-right: 80px;
                flex-basis: 80%;

                @media(max-width:1200px) {
                    flex-basis: 100%;
                    margin-right: 0;
                }

                span {
                    text-transform: uppercase;
                    font-size: 16px;
                    font-weight: 700;
                    letter-spacing: 3px;
                    color: $theme-primary-color-s3;
                }

                h2 {
                    font-size: 50px;
                    color: $white;
                    margin-top: 20px;

                    @media(max-width:1200px) {
                        font-size: 40px;
                        margin-bottom: 20px;
                    }

                    @media(max-width:650px) {
                        font-size: 30px;
                    }
                }

            }

            .wpo-cta-btn {
                flex-basis: 20%;

                @media(max-width:1200px) {
                    flex-basis: 100%;
                }

                a {
                    display: inline-block;
                    padding: 15px 35px;
                    color: $white;
                    position: relative;
                    border-radius: 6px;
                    margin-top: 10px;
                    max-width: 150px;
                    text-transform: uppercase;
                    background: $theme-primary-color;

                    @media(max-width:991px) {
                        border: 1px solid $white;
                    }


                    &:hover {
                        background: darken($theme-primary-color, 8%);
                        color: $white;
                    }
                }

            }

            .cta-shape {
                position: absolute;
                left: -1px;
                bottom: 0;
                z-index: -1;

                svg {
                    fill: $theme-primary-color;
                }
            }
        }
    }

    &.wpo-cta-section-s2 {
        padding-top: 120px;

        @media (max-width:991px) {
            padding-top: 90px;
        }

        @media (max-width:767px) {
            padding-top: 80px;
        }
    }
}
