//* 3.2 wpo-election-donation-section *//
.wpo-election-donation-section,
.wpo-election-donation-section-s2 {
    padding-bottom: 90px;

    @media (max-width:991px) {
        padding-bottom: 60px;
    }

    @media (max-width:767px) {
        padding-bottom: 50px;
    }

    .election-donation-item {
        background: $theme-primary-color-s2;
        text-align: center;
        padding: 45px 35px;
        transition: all .4s ease-in-out;
        margin-bottom: 30px;

        @media (max-width:1199px) {
            padding: 40px 15px;
        }

        @media (max-width:767px) {
            padding: 45px 35px;
        }

        @media (max-width:399px) {
            padding: 40px 15px;
        }

        &.color-2 {
            background: $theme-primary-color-s3;
        }

        &.color-3 {
            background: $theme-primary-color;
        }

        .logo {
            border: 1px solid $white;
            border-radius: 100px;
            border-style: dashed;
            width: 110px;
            height: 110px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
        }

        .text {
            margin-top: 40px;

            h2 {
                color: $white;
                font-size: 24px;
                font-weight: 600;
                line-height: 35px;
                margin-bottom: 12px;
                text-transform: uppercase;
            }

            p {
                color: $white;
                font-size: 16px;
                font-weight: 400;
                line-height: 30px;
                margin-bottom: 0;
            }

            .icon {
                margin-top: 33px;
                transition: all .4s ease-in-out;

                a {
                    width: 45px;
                    height: 45px;
                    background: $white;
                    border-radius: 100px;
                    margin: 0 auto;
                    display: flex;
                    align-items: center;
                    transition: all .4s ease-in-out;
                    color: $theme-primary-color;
                    justify-content: center;

                    &:hover {
                        background: $text-light-color-s2;
                        color: $white;
                    }

                    i {
                        &::before {
                            font-size: 20px;
                            line-height: 25px;
                        }
                    }
                }
            }
        }

    }
}

//* 3.3 wpo-running-campaign-section *//
.wpo-running-campaign-section {
    position: relative;
    z-index: 1;
    background: url(../../images/donate-bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;

    &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: $dark-gray;
        opacity: 0.88;
        z-index: -1;
    }

    .running-campaign-wrap {
        background: $white;
        padding: 70px;
        overflow: hidden;
        position: relative;
        z-index: 1;

        @media (max-width:1199px) {
            padding: 40px;
        }

        @media (max-width:991px) {
            padding: 70px;
        }

        @media (max-width:767px) {
            padding: 40px;
        }

        @media (max-width:499px) {
            padding: 20px;
        }

        .shape-1 {
            position: absolute;
            top: 50%;
            left: -25%;
            width: 100%;
            height: 171%;
            transform: translate(-50%, -50%);
            z-index: -1;
            border: 43px solid #274DCF;
            border-radius: 100%;

            @media (max-width:991px) {
                display: none;
            }
        }

        .shape-2 {
            position: absolute;
            top: 50%;
            left: -22%;
            width: 100%;
            height: 183%;
            transform: translate(-50%, -50%);
            z-index: -1;
            border: 43px solid #DE2045;
            border-radius: 100%;

            @media (max-width:991px) {
                display: none;
            }
        }

        .campaign-img {
            margin-right: 50px;

            @media (max-width:1199px) {
                margin-right: 0;
            }

            @media (max-width:991px) {
                margin-bottom: 40px;
            }

            img {
                width: 100%;
                border-radius: 8px;
            }
        }

        .running-campaign-content {
            .sub-title {
                font-size: 15px;
                font-weight: 600;
                line-height: 19px;
                letter-spacing: 0.18em;
                color: $theme-primary-color;

            }

            .title {
                margin-top: 12px;
                font-size: 42px;
                font-weight: 600;
                line-height: 50px;
                text-align: left;
                margin-bottom: 23px;
                text-transform: capitalize;

                @media (max-width:767px) {
                    font-size: 35px;
                    line-height: 40px;
                }

                @media (max-width:448px) {
                    font-size: 29px;
                    line-height: 38px;
                }
            }

            .donate-progress-wrap {
                margin-bottom: 40px;

                .donate-progress {
                    position: relative;
                    overflow: hidden;
                    width: 100%;
                    height: 8px;
                    background: #DEE3F2;
                    border-radius: 31px;

                    .cssProgress-bar {
                        display: block;
                        float: left;
                        width: 0%;
                        height: 100%;
                        background: $theme-primary-color-gradient;
                        box-shadow: inset 0px -1px 2px rgba(0, 0, 0, 0.1);
                        transition: width 0.8s ease-in-out;
                        border-radius: 31px;

                        .cssProgress-label {
                            position: absolute;
                            overflow: hidden;
                            left: 0px;
                            right: 0px;
                            color: rgba(0, 0, 0, 0.6);
                            font-size: 0;
                            text-align: center;
                            text-shadow: 0px 1px rgba(0, 0, 0, 0.3);
                        }
                    }
                }

                .progress-text {
                    display: flex;
                    align-content: center;
                    justify-content: space-between;
                    margin-top: 20px;

                    .goal {
                        span {
                            font-weight: 400;
                            font-size: 15px;
                            line-height: 30px;
                            color: #6C7187;
                        }

                        strong {
                            font-weight: 600;
                            font-size: 15px;
                            line-height: 30px;
                            color: #091854;
                        }

                        &.raised {
                            width: 50%;
                            text-align: center;
                        }
                    }
                }
            }

            .theme-btn-s3 {
                color: $dark-gray;

                &:hover {
                    color: $white;
                }
            }
        }

        .shape-3 {
            position: absolute;
            top: 87%;
            left: 90%;
            transform: translate(-50%, -50%);
            z-index: -1;

            @media (max-width:991px) {
                top: 93%;
                left: 85%;
            }

        }

        .shape-4 {
            position: absolute;
            top: 12%;
            left: 63%;
            transform: translate(-50%, -50%);
            z-index: -1;

            @media (max-width:991px) {
                top: 2%;
            }
        }
    }

    &.wpo-running-campaign-section-s2 {
        margin: 120px 0;

        @media (max-width:991px) {
            margin: 90px 0;
        }

        @media (max-width:767px) {
            margin: 80px 0;
        }
    }

}

//* 3.4 wpo-election-mission-section *//
.wpo-election-mission-section {
    position: relative;
    z-index: 1;
    padding-bottom: 90px;

    @media (max-width:991px) {
        padding-bottom: 60px;
    }

    @media (max-width:767px) {
        padding-bottom: 50px;
    }

    .election-mission-wrap {
        margin-top: 25px;

        .election-mission-content {
            position: relative;
            overflow: hidden;
            transition: all .4s ease-in-out;
            margin-bottom: 30px;

            &::before {
                position: absolute;
                left: 0;
                top: 0;
                content: "";
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, #091854 100%);
                opacity: 0.8;
                transition: all .4s ease-in-out;
            }

            &::after {
                position: absolute;
                left: 0;
                top: 0;
                content: "";
                width: 100%;
                height: 0;
                opacity: 0;
                visibility: hidden;
                transition: all .4s ease-in-out;
                background: linear-gradient(90deg, #2D49BD 0%, #DE2045 100%);
            }

            img {
                width: 100%;
            }

            .title {
                position: absolute;
                bottom: 20px;
                left: 50%;
                transform: translateX(-50%);
                background: $white;
                font-family: $heading-font;
                font-size: 18px;
                font-weight: 600;
                color: $heading-color;
                text-align: center;
                width: 80%;
                padding: 20px 0;
                display: block;
                transition: all .4s ease-in-out;
                z-index: 1;
                text-transform: uppercase;

                @media (max-width:1199px) {
                    width: 95%;
                    padding: 10px 0;
                }

                @media (max-width:575px) {
                    width: 70%;
                    padding: 20px 0;
                }

                @media (max-width:399px) {
                    width: 95%;
                    padding: 10px 0;
                }
            }

            .text {
                position: absolute;
                bottom: -80%;
                left: 50%;
                transform: translateX(-50%);
                text-align: left;
                width: 80%;
                padding: 20px 0;
                transition: all .4s ease-in-out;
                z-index: 1;
                color: $white;

                @media (max-width:1199px) {
                    width: 90%;
                }

                @media (max-width:575px) {
                    width: 70%;
                }

                @media (max-width:399px) {
                    width: 95%;
                }

                h3 {
                    margin-bottom: 20px;
                    font-weight: 600;
                    font-size: 21px;
                    line-height: 25px;
                    text-transform: uppercase;
                    color: $white;
                }

                p {
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 28px;
                    color: $white;
                    margin-bottom: 0;
                }
            }

            &:hover {
                &::before {
                    opacity: 0;
                }

                &::after {
                    height: 100%;
                    opacity: 1;
                    visibility: visible;
                    opacity: 0.84;
                }

                .title {
                    left: -100%;
                }

                .text {
                    bottom: 4%;
                }


            }

        }
    }

    .shape-1 {
        position: absolute;
        bottom: 0;
        left: 30%;
        transform: translateX(-50%);
        z-index: -1;

        svg {
            path {
                fill: #F8F2F2;
            }
        }
    }

    .shape-2 {
        position: absolute;
        bottom: 0;
        right: -30%;
        transform: translateX(-50%);
        z-index: -11;

        svg {
            path {
                fill: rgba(248, 242, 242, 0.588);
            }
        }
    }

}

//* 3.5 wpo-event-section *//
.wpo-event-section {
    position: relative;
    background: $section-bg-color;
    padding: 150px 0;
    z-index: 1;
    padding-bottom: 120px;

    @media (max-width:991px) {
        padding-bottom: 90px;
        padding-top: 120px;
    }

    @media (max-width:767px) {
        padding-bottom: 80px;
        padding-top: 110px;
    }

    .event-wrap {
        .wpo-section-title {
            text-align: left;
            position: relative;
            margin-left: 130px;
            margin-top: 124px;

            @media (max-width:1655px) {
                margin-left: 40px;
                margin-top: 90px;
            }

            @media (max-width:1655px) {
                margin-left: 40px;
                margin-top: 90px;
            }

            @media (max-width:1399px) {
                margin-left: 40px;
                margin-top: 80px;
            }

            @media (max-width:1295px) {
                margin-left: 15px;
                margin-top: 70px;
            }

            @media (max-width:1199px) {
                margin-left: 5px;
                margin-top: 45px;
            }

            @media (max-width:991px) {
                text-align: center;
                margin-bottom: 90px;
            }

            h2 {
                @media (max-width:1199px) {
                    font-size: 35px;
                    line-height: 50px;
                }

                @media (max-width:991px) {
                    font-size: 42px;
                    line-height: 55px;
                }

                @media (max-width:385px) {
                    font-size: 34px;
                    line-height: 48px;
                }
            }

            &::before {
                position: absolute;
                left: 0;
                top: 115%;
                content: '';
                width: 100px;
                height: 5px;
                background: linear-gradient(90deg, rgba(222, 32, 69, 1) 50%, rgba(222, 32, 69, 1) 50%, rgba(39, 77, 207, 1) 50%);

                @media (max-width:991px) {
                    left: 42%;
                }

                @media (max-width:767px) {
                    left: 40%;
                }

                @media (max-width:385px) {
                    left: 33%;
                }
            }
        }

        .event-item {
            padding: 0 50px 50px 50px;
            margin-bottom: 30px;
            position: relative;
            z-index: 1;
            overflow: hidden;

            @media (max-width:1690px) {
                padding: 0 30px 50px 30px;
            }

            @media (max-width:1399px) {
                padding: 0 15px 40px 15px;
            }

            @media (max-width:991px) {
                padding: 0 40px 50px 40px;
            }

            @media (max-width:767px) {
                padding: 0 50px 50px 50px;
            }

            @media (max-width:509px) {
                padding: 0 35px 40px 35px;
            }

            @media (max-width:445px) {
                padding: 0 20px 30px 20px;
            }

            &::before {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                content: '';
                z-index: -1;
                background: $white;
                clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
                transition: all .4s ease-in-out;
            }

            .image {
                overflow: hidden;

                img {
                    width: 100%;
                    transition: all .4s ease-in-out;
                }
            }

            .event-content {
                margin-top: 35px;

                span {
                    color: $text-light-color-s2;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;

                    @media (max-width:1399px) {
                        font-size: 13px;
                    }

                    @media (max-width:1199px) {
                        font-size: 10px;
                    }

                    @media (max-width:991px) {
                        font-size: 14px;
                    }

                    @media (max-width:991px) {
                        font-size: 14px;
                    }

                    i {
                        font-size: 15px;
                        color: $dark-gray;
                        margin-right: 10px;

                        @media (max-width:1199px) {
                            font-size: 10px;
                        }

                        @media (max-width:991px) {
                            font-size: 15px;
                        }
                    }
                }


                h2 {
                    margin-top: 10px;
                    margin-bottom: 14px;

                    @media (max-width:1199px) {
                        line-height: 20px;
                    }

                    @media (max-width:991px) {
                        line-height: 32px;
                    }

                    a {
                        font-family: $heading-font;
                        font-size: 25px;
                        font-weight: 600;
                        line-height: 32px;
                        color: $heading-color;
                        text-transform: capitalize;
                        transition: all .4s ease-in-out;

                        @media (max-width:1599px) {
                            font-size: 22px;
                            line-height: 28px;
                        }

                        @media (max-width:1399px) {
                            font-size: 20px;
                            line-height: 25px;
                        }

                        @media (max-width:1199px) {
                            font-size: 16px;
                        }

                        @media (max-width:991px) {
                            font-size: 20px;
                        }
                    }
                }

                .event-date {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .date {


                        i {}
                    }

                    .time {


                        i {
                            color: $theme-primary-color;
                        }
                    }
                }
            }

            &:hover {

                &::before {
                    clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 100%);
                }

                .image {

                    img {
                        transform: scale(1.2);
                    }
                }

                .event-content {


                    h2 {

                        a {
                            color: $theme-primary-color;
                        }
                    }
                }
            }
        }
    }

    .shape-1 {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;

        svg {
            rect {
                fill: linear-gradient(90deg, #DE2045 0%, rgba(217, 217, 217, 0) 100%);
            }
        }
    }

    .shape-2 {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;

        svg {
            rect {
                fill: linear-gradient(90deg, #274DCF 0%, rgba(217, 217, 217, 0) 100%);
            }
        }
    }

    .shape-3 {
        position: absolute;
        top: 26%;
        left: 32%;
        transform: translate(-50%, -50%);
        z-index: -11;

        @media (max-width:1295px) {
            display: none;
        }

        svg {
            circle {
                fill: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
            }
        }
    }

    .shape-4 {
        position: absolute;
        top: 78%;
        left: 10%;
        transform: translate(-50%, -50%);
        z-index: -11;

        @media (max-width:1295px) {
            display: none;
        }

        svg {
            circle {
                fill: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
            }
        }
    }
}

//* 3.6 wpo-testimonial-section *//
.wpo-testimonial-section {
    position: relative;
    padding-bottom: 0;

    @media (max-width:991px) {
        padding-bottom: 0;
    }

    @media (max-width:767px) {
        padding-bottom: 0;
    }

    .testimonial-wrap {
        position: relative;
        background: url(../../images/testimonial-bg.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0 165px;
        margin-top: 25px;
        margin-bottom: 12px;
        z-index: 1;

        @media (max-width:1599px) {
            margin-bottom: 0;
        }

        @media (max-width:1299px) {
            margin: 0 60px;
        }

        @media (max-width:1199px) {
            margin: 0 50px;
        }

        @media (max-width:991px) {
            margin: 0;
        }

        &::after {
            position: absolute;
            content: "";
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: $dark-gray;
            opacity: 0.85;
            z-index: -1;
        }

        .testimonial-image {
            text-align: center;
            margin-top: 80px;

            @media (max-width:340px) {
                margin-left: -15px;
            }

            img {
                width: 70px!important;
                height: 70px!important;
                border-radius: 50%;
            }
        }

        .testimonial-content {
            position: relative;
            max-width: 1100px;
            margin: 0 auto;

            .text {
                text-align: center;
                margin-top: 40px;
                margin-bottom: 80px;

                @media (max-width:767px) {
                    margin: 0;
                    margin-top: 40px;
                    margin-bottom: 80px;
                }

                p {
                    font-family: $base-font;
                    font-size: 22px;
                    font-weight: 400;
                    line-height: 40px;
                    text-align: center;
                    margin-bottom: 0;
                    color: $white;

                    @media (max-width:767px) {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }

                h2 {
                    font-size: 30px;
                    font-weight: 600;
                    line-height: 47px;
                    color: $white;
                    margin-top: 35px;
                    text-transform: capitalize;
                    margin-bottom: 0;

                    @media (max-width:767px) {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }

                span {
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.2em;
                    color: $theme-primary-color-s3;
                    margin-bottom: 0;
                    font-family: $base-font;
                }
            }
        }

        .slider-nav{
            position: relative;

            img {
                transform: scale(0.8);
                margin: auto;
                transition: 0.2s;
                border-radius: 50%;
                opacity: 0.7;
                max-width: 200px;
            }

            .slick-center {
                border: 0;
                outline: 0;
            }

            .slick-center img {
                transform: scale(1);
                opacity: 1;
            }

            .slick-prev {
                z-index: 1;
                transform: translate(-50%, -50%);
                top: 40%;

            }

            .slick-next {
                z-index: 1;
                top: 40%;
            }
        }

        .slider-nav {

            button i {
                font-size: 14px;
                color: #d4d4d4;
                position: absolute;
                left: 0;
            }

            .slick-next i {
                left: auto;
                right: 0;
            }

        }

        /** slider controls **/
        .slick-prev,
        .slick-next {
            background-color: transparentize($theme-primary-color, 0.7);
            width: 55px;
            height: 55px;
            z-index: 10;
            border-radius: 50%;
            @include transition-time(0.5s);

            &:hover {
                background-color: transparentize($theme-primary-color, 0.4);
            }

            @include media-query(991px) {
                display: none !important;
            }
        }

        .slick-prev {
            left: -100px;

            &:before {
                font-family: "themify";
                content: "\e629";
                opacity: 1;
            }
        }

        .slick-next {
            right: -100px;

            &:before {
                font-family: "themify";
                content: "\e628";
                opacity: 1;
            }
        }





        .slick-dots {
            bottom: 30px;

            @include widther(992px) {
                display: none !important;
            }

            li {
                margin: 0;
            }

            button {
                background-color: $theme-primary-color;
                width: 14px;
                height: 14px;
                border: 2px solid $white;
                border-radius: 50%;
            }

            button:before,
            button:before {
                display: none;
            }
        }

        .shape-1 {
            position: absolute;
            width: 100%;
            bottom: -27px;
            left: 50%;
            z-index: -1;
            transform: translate(-50%, -50%);

            @media (max-width:1599px) {
                display: none;
            }

            svg {
                rect {
                    fill: linear-gradient(270deg, #274DCF 0%, rgba(255, 255, 255, 0) 100%);
                }
            }
        }

        .shape-2 {
            position: absolute;
            width: 100%;
            bottom: -27px;
            left: 100%;
            z-index: -1;
            transform: translate(-50%, -50%);

            @media (max-width:1599px) {
                display: none;
            }

            svg {
                rect {
                    fill: linear-gradient(90deg, #DE2045 0%, rgba(255, 255, 255, 0) 100%);
                }
            }
        }
    }

    &.wpo-testimonial-section-s2 {
        padding-bottom: 120px;

        @media (max-width:991px) {
            padding-bottom: 90px;
        }

        @media (max-width:767px) {
            padding-bottom: 80px;
        }
    }

}

//* 3.7 wpo-blog-section *//
.wpo-blog-section {
    position: relative;
    padding-bottom: 90px;

    @media (max-width:991px) {
        padding-bottom: 60px;
    }

    @media (max-width:767px) {
        padding-bottom: 50px;
    }

    .blog-wrap {


        .blog-item {
            position: relative;
            margin-bottom: 30px;

            .image {
                img {
                    width: 100%;
                }
            }

            .dete {
                position: absolute;
                left: 17%;
                top: 47%;
                transform: translate(-50%, -50%);
                width: 70px;
                background: $section-bg-color;
                text-align: center;
                padding: 10px 0;

                @media (max-width:1399px) {
                    top: 41%;
                }

                @media (max-width:1199px) {
                    top: 34%;
                }

                @media (max-width:991px) {
                    top: 39%;
                }

                @media (max-width:767px) {
                    top: 52%;
                }

                @media (max-width:575px) {
                    top: 56%;
                }

                @media (max-width:553px) {
                    top: 52%;
                }

                @media (max-width:499px) {
                    top: 48%;
                }

                @media (max-width:430px) {
                    top: 42%;
                }

                @media (max-width:358px) {
                    top: 35%;
                }

                span {
                    font-family: $base-font;
                    font-size: 29px;
                    font-weight: 600;
                    line-height: 32px;
                    text-align: center;
                    color: $theme-primary-color;
                    display: block;

                }

                strong {
                    font-family: $heading-font;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 17px;
                    text-align: center;
                    color: #7A7D8A;
                    text-transform: uppercase;
                }
            }

            .blog-content {
                padding: 60px 35px 40px;
                border: 1px solid #F9EFEF;
                border-top: 0px;

                h2 {
                    color: $heading-color;
                    font-size: 25px;
                    font-weight: 600;
                    line-height: 32px;
                    margin-bottom: 20px;
                    text-transform: capitalize;
                }

                p {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 28px;
                    color: #999BA5;
                    margin-bottom: 25px;
                }

            }
        }
    }
}