/*======================================
18. wpo-donation-page-area
 =======================================*/

.wpo-donate-header{
    text-align: center;
    margin-bottom: 50px;
    h2{
        font-weight: 700;
        text-transform: capitalize;
        margin-bottom: 0;
    }
}
.wpo-donations-amount,
.wpo-donations-details,
.wpo-doanation-payment {
    padding: 30px;
    border: 1px solid #ebebeb;
    margin-bottom: 40px;
}

.wpo-donations-amount h2,
.wpo-donations-details h2,
.wpo-doanation-payment h2 {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 600;
    position: relative;
    text-transform: capitalize;
    margin-bottom: 30px;
    margin-top: 0;
}

.wpo-donations-amount input,
.wpo-donations-details input,
.wpo-donations-details textarea {
    background: transparent;
    width: 100%;
    height: 50px;
    border: 0;
    border: 1px solid #ebebeb;
    padding-left: 15px;
    border-radius: 0;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    color: #525252;
    border-radius: 5px;
    margin-bottom: 30px;
}

.wpo-donations-details textarea {
    height: 135px;
}
.wpo-donations-details .form-group .error{
    margin-bottom: 20px;
}

.wpo-donations-amount input:focus,
.wpo-donations-details input:focus,
.wpo-donations-details textarea:focus {
    border-color: $theme-primary-color;
}

.payment-name ul li input {
    margin-right: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.wpo-payment-select ul {
    list-style: none;
}

.payment-name ul {
    display: flex;
    margin-top: 30px;
    flex-wrap: wrap;
}

.wpo-payment-area form {
    overflow: unset !important;
}

.payment-name ul li {
    width: 100px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    margin-bottom: 5px;
}

.payment-name ul {
    list-style: none;
}

.payment-name ul li input {
    margin-right: 0;
}

.payment-name ul li {
    margin-right: 15px;
}

.wpo-payment-area h2 {
    padding-bottom: 40px;
    margin-bottom: 0;
}

.wpo-payment-select ul {
    display: flex;
}

.wpo-payment-select ul li {
    margin-right: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #5B5B5B;
}

.wpo-payment-select label {
    font-size: 18px;
    font-weight: 600;
}

.wpo-payment-area h2 {
    font-size: 20px;
    color: #878787;
    font-weight: 700;
}

.wpo-payment-area h2 span {
    font-size: 30px;
    color: #ff493c;
}

.wpo-payment-area {
    width: 100%;
}

.payment-name ul li input {
    margin-right: 0;
    position: absolute;
    z-index: -1;
}

.payment-name ul li input:checked~label {
    background: #fcebbd;
}

.payment-name label {
    width: 100%;
    border: 1px solid transparent;
    cursor: pointer;
}

.payment-name .visa label {
    border: 1px solid #0057A0;
}

.payment-name .mas label {
    border: 1px solid #CC0000;
}

.payment-name .ski label {
    border: 1px solid #691A5F;
}

.payment-name .pay label {
    border: 1px solid #019CDE;
}

.wpo-payment-option {
    padding: 0 15px;
}

.payment-name.active {
    display: block;
}

.wpo-payment-option.active .payment-name {
    display: none !important;
}

.wpo-payment-area .form-style input {
    width: 100%;
    margin-bottom: 15px;
    padding-left: 20px;
    height: 40px;
    background: #fff;
    border-radius: 2px;
    border: none;
    border: 1px solid #ebebeb;
}

.wpo-payment-area .form-style input:focus {
    border: 1px solid $theme-primary-color;
}

.wpo-payment-area .contact-form {
    margin-top: 40px;
}

#Donations .theme-btn {
    border: none;
    outline: none;
    box-shadow: none;
}

#Donations .submit-area {
    text-align: center;
}